import { MediaType } from '@lp-lib/media';

import { once } from './common';
import { releaseVideoElement } from './video';

/**
 * An interface that represents an HTMLMediaElement that is "unplayable" e.g.
 * unloaded until the programmer specifies that it shall load. It couples the
 * source and the element together.
 */
export interface UnplayableMedia<
  T extends HTMLVideoElement | HTMLImageElement | HTMLAudioElement =
    | HTMLVideoElement
    | HTMLImageElement
    | HTMLAudioElement
> {
  readonly source: T extends HTMLVideoElement
    ? string | MediaStream
    : T extends HTMLAudioElement
    ? string | MediaStream | ArrayBuffer | Blob
    : string;
  readonly media: T;
  intoPlayable: (forceInitialContentLoaded?: boolean) => Promise<T>;
}

// For convenience. But proably better to always use UnplayableMedia.
export type UnplayableVideo = UnplayableMedia<HTMLVideoElement>;
export type UnplayableImage = UnplayableMedia<HTMLImageElement>;
export type UnplayableAudio = UnplayableMedia<HTMLAudioElement>;

/**
 * Create a video element that exists, but is "unplayable" e.g. it has a known
 * src but is unassigned and no content loaded. Then allow converting this
 * resource into a "playable" video by assigning the src, loading the video, and
 * optionally ensuring the first frame is rendered by Chrome. If `intoPlayable`
 * is called multiple times, only the first will have any side-effects. The same
 * promise will always be returned.
 */
export class UnplayableVideoImpl implements UnplayableMedia<HTMLVideoElement> {
  private playable: Promise<HTMLVideoElement> | null = null;

  constructor(
    public readonly source: string | MediaStream,
    public readonly media = document.createElement('video')
  ) {
    media.crossOrigin = 'anonymous';
  }

  intoPlayable(forceFirstFrame = true): Promise<HTMLVideoElement> {
    if (this.playable) return this.playable;

    const video = this.media;
    this.playable = Promise.race([
      once(video, 'error', true),
      once(video, 'canplay'),
    ])
      .then(() => video)
      .then(async () => {
        if (!forceFirstFrame) return video;

        // Chrome has an "optimization" or "bug" where the first frame of the
        // video will be delayed when painting to a 2d canvas or webgl canvas,
        // even after waiting for various events like loadeddata, canplay,
        // canplaythrough, etc. This results in either a transparent first frame
        // (canvas) or black (webgl). Displaying the video in the document seems
        // to mitigate it, but that is not an option here. The only workaround I
        // could find was setting the currentTime to near the beginning, enough
        // to get chrome to actually request the frame data. Solution source:
        // https://stackoverflow.com/q/65718478/169491#comment116196115_65718478
        // Bug Report since Chrome 76:
        // https://support.google.com/chrome/thread/12663566/chrome-76-bug-canvas-cannot-drawimage-of-video-s-first-frame?hl=en

        video.currentTime = 0.001;
        await once(video, 'seeked');
        video.currentTime = 0;
        await once(video, 'seeked');
        return video;
      });
    if (typeof this.source === 'string') {
      video.src = this.source;
    } else {
      video.srcObject = this.source;
    }
    return this.playable;
  }
}

/**
 * Create an image element that exists, but is "unplayable" e.g. it has a known
 * src but is unassigned and no content loaded. Then allow converting this
 * resource into a "playable" image by assigning the src, loading the image. If
 * `intoPlayable` is called multiple times, only the first will have any
 * side-effects. The same promise will always be returned.
 *
 * Note: the "unplayable" is a carryover from the first and similarly named
 * UnplayableVideo implementation.
 */
export class UnplayableImageImpl implements UnplayableMedia<HTMLImageElement> {
  private playable: Promise<HTMLImageElement> | null = null;

  constructor(
    public readonly source: string,
    public readonly media = document.createElement('img')
  ) {
    media.crossOrigin = 'anonymous';
  }

  intoPlayable(): Promise<HTMLImageElement> {
    if (this.playable) return this.playable;

    const image = this.media;
    this.playable = Promise.race([
      once(image, 'error', true),
      once(image, 'load'),
    ]).then(() => image);
    image.src = this.source;

    return this.playable;
  }
}

/**
 * Create an audio element that exists, but is "unplayable" e.g. it has a known
 * src but is unassigned and no content loaded. Then allow converting this
 * resource into a "playable" audio by assigning the src, and loading the data.
 * If `intoPlayable` is called multiple times, only the first will have any
 * side-effects. The same promise will always be returned.
 */
export class UnplayableAudioImpl implements UnplayableMedia<HTMLAudioElement> {
  private playable: Promise<HTMLAudioElement> | null = null;

  constructor(
    public readonly source: string | MediaStream,
    public readonly media = document.createElement('audio')
  ) {
    media.crossOrigin = 'anonymous';
  }

  intoPlayable(): Promise<HTMLAudioElement> {
    if (this.playable) return this.playable;

    const audio = this.media;
    this.playable = Promise.race([
      once(audio, 'error', true),
      once(audio, 'canplay'),
    ]).then(() => audio);
    if (typeof this.source === 'string') {
      audio.src = this.source;
    } else {
      audio.srcObject = this.source;
    }
    return this.playable;
  }
}

export class UnplayableMediaFactory {
  static From(
    source: string | MediaStream,
    mediaType: MediaType.Video
  ): UnplayableMedia<HTMLVideoElement>;
  static From(
    source: string | MediaStream,
    mediaType: MediaType.Audio
  ): UnplayableMedia<HTMLAudioElement>;
  static From(
    source: string,
    mediaType: MediaType.Image
  ): UnplayableMedia<HTMLImageElement>;
  static From(
    source: string,
    mediaType: MediaType.Image | MediaType.Video
  ): UnplayableMedia<HTMLImageElement | HTMLVideoElement>;
  static From(
    source: string,
    mediaType: MediaType.Image | MediaType.Video | MediaType.Audio
  ): UnplayableMedia<HTMLImageElement | HTMLVideoElement | HTMLAudioElement>;
  static From(
    source: string | MediaStream,
    mediaType: MediaType
  ):
    | UnplayableMedia<HTMLImageElement>
    | UnplayableMedia<HTMLVideoElement>
    | UnplayableMedia<HTMLAudioElement>
    | UnplayableMedia<HTMLVideoElement | HTMLImageElement> {
    if (mediaType === MediaType.Image && typeof source === 'string')
      return new UnplayableImageImpl(source);
    else if (mediaType === MediaType.Video)
      return new UnplayableVideoImpl(source);
    else if (mediaType === MediaType.Audio)
      return new UnplayableAudioImpl(source);
    else throw new Error('Incompatible parameters');
  }

  static Release(unplayable: UnplayableMedia | undefined | null): void {
    if (!unplayable) return;
    if (unplayable.media instanceof HTMLVideoElement) {
      releaseVideoElement(unplayable.media);
    }
  }
}

export class UnplayableBytes implements UnplayableMedia<HTMLAudioElement> {
  private playable: Promise<HTMLMediaElement> | null = null;
  media: HTMLMediaElement;

  constructor(
    public readonly source: ArrayBuffer | Blob,
    public readonly mime?: string
  ) {
    if (source instanceof ArrayBuffer) {
      if (!this.mime)
        throw new Error('mime required for raw bytes (ArrayBuffer)!');
    } else {
      this.mime = source.type;
    }
    this.media = this.mime.startsWith('video/')
      ? document.createElement('video')
      : document.createElement('audio');
    this.media.crossOrigin = 'anonymous';
  }

  async intoPlayable(): Promise<HTMLMediaElement> {
    if (this.playable) return this.playable;
    const blob =
      this.source instanceof Blob
        ? this.source
        : new Blob([this.source], { type: this.mime });
    const url = URL.createObjectURL(blob);
    this.playable = Promise.race([
      once(this.media, 'error'),
      once(this.media, 'canplay'),
    ]).then(() => this.media);
    this.media.src = url;
    return this.playable;
  }
}
